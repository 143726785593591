<template>
	<div class="order_wrap">
		<v-container class="">
			<MStepper />
			<div class="txt_box px-6">
				<h1 class="tit_page">수납 (신용카드) 할부 선택</h1>
				<p class="txt_body1 mb-8">
					다음 카드 수납에 대해 할부기간을 선택 합니다.
				</p>
			</div>
			<v-form class="form px-6">
				<div class="wrap_input">
					<h3 class="label">카드정보</h3>
					<v-text-field outlined v-model="cardInfo" disabled hint="25" dense />
				</div>
				<div class="wrap_input">
					<h3 class="label">할부기간 선택</h3>
					<MSelectBottom
						v-model="monthInstallment"
						title="일시불"
						:items="monthItems"
						@item-selected="handleMonthInstallmentSelected"
						><template
							>{{ monthInstallmentDisplayText
							}}<span class="btn_value"></span></template
					></MSelectBottom>
				</div>
			</v-form>
			<div class="bottom_box px-6">
				<v-btn color="primary" class="col_1_small" @click="goRecp()">
					{{ amount | comma }}원 수납 진행
				</v-btn>
				<v-btn
					depressed
					height="20"
					@click="onResetPayMethod"
					color=""
					class="btn_underline my-6"
				>
					수납방법 재선택
				</v-btn>
				<CustomRecpDialog ref="recpPop" />
			</div>
		</v-container>
	</div>
</template>

<script>
import filters from '@/mixins/filters'
import { cardTransfer } from '@/apis/order.recp.api'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import { sendAlimTalk, fetchEContract } from '@/apis/order.ec.api'
import { getInsMonthOfCard } from '@/utils'
export default {
	mixins: [filters],
	components: {},
	mounted() {
		this.recp = this.$route.params.recp
		const amt = this.$options.filters.star(this.recp.AF_CARD_NO, 6)
		this.cardInfo = this.recp.AF_BANK_CD_NM + amt
		this.prm = this.$route.params.prm
		this.amount = this.$route.params.amount
	},
	data() {
		return {
			monthItems: getInsMonthOfCard(),
			monthInstallment: '00',
			monthInstallmentDisplayText: '일시불',
			recp: {},
			cardInfo: '',
			param: {},
			prm: {},
			card: {},
			amount: '',
			isPreContract: this.$store.state['verify'].customer.orderType === 'C'
		}
	},
	methods: {
		handleMonthInstallmentSelected(item) {
			this.monthInstallmentDisplayText = item.text
		},
		onResetPayMethod() {
			this.$router.go(-1)
			// this.$log('--->', this.monthInstallment)
		},
		async goRecp() {
			const options = {
				title:
					'다음 카드를 통해 결제를 요청합니다.<br />결제는 설치정보 작성 완료 후 진행됩니다.',
				contents: {
					수납방법: `${this.recp.AF_BANK_CD_NM}
						${this.$options.filters.star(this.recp.AF_CARD_NO, 6)}`,
					수납금액: `${this.$options.filters.comma(this.amount)}원`
				},
				okText: '즉시이체',
				cancelText: '닫기'
			}

			const popRes = await this.$refs.recpPop.open(options)
			if (popRes) {
				let prm = this.prm
				prm.insMon = this.monthInstallment
				const res = await cardTransfer(this.prm)
				const rtn = res.resultObject.data.E_RETURN
				await this.chkReturn(this.prm, rtn, res.resultObject.data.TC_CARD[0])
			}
		},
		async chkReturn(prm, rtn, tcObj) {
			prm.insMon = this.monthInstallment
			this.$log('prm.insMon', prm.insMon)
			if (rtn.TYPE === 'S') {
				if (this.status === '106') {
					// 직접인증 가상계좌 서명완료 후 재진입
					await this.$alert({
						message: '수납이 완료됐습니다.<br />주문을 확정합니다.',
						okText: '주문확정'
					})
				} else {
					await this.$alert({
						message:
							'결제는 설치정보 작성 완료 후 진행됩니다.<br />설치 정보 화면으로 이동합니다.',
						okText: '계속 진행하기'
					})
				}

				// store 저장
				this.$store.dispatch('recp/setRecpParams', { ...prm })

				// 웹 주문정보 저장
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']

				const res = await fetchWebOrderInfoById(mobOrderNo)
				let webParams = res.resultObject
				let order = JSON.parse(webParams.orderInfoJson)
				this.webOrderSum = order.PAY_SUMMARY_AMT

				order = Object.assign(order, {
					recp: {
						recpParams: this.$store.getters['recp/recpParams'],
						recpInfos: this.$store.getters['recp/recpInfos'],
						isCustSelfPay: false,
						payRecp: this.$store.getters['recp/payRecp']
						// tcObj 추가는 아래에서...
					}
				})

				if (prm.payMthd === 'BB' || prm.payMthd === '02') {
					order.recp.recpCardResult = tcObj
				} else {
					order.recp.recpBankResult = tcObj
				}

				if (this.status === '106') {
					webParams.step = '07' // 주문확정
					webParams.statusCode = this.isPreContract ? '112' : '109' // 주문확정 or 배정대기
				} else {
					webParams.step = '05' // 설치정보
					webParams.statusCode = '107' // 설치입력
				}
				webParams.orderInfoJson = JSON.stringify(order)

				const postRes = await postWebOrderInfoById(mobOrderNo, webParams)
				this.$log('postRes: ', JSON.stringify(postRes))

				if (this.status === '106') {
					// 진행현황
					// 알림톡 발송
					let ecParams = {
						mobOrderNo: this.$store.getters['verify/mobOrderNo']
					}
					const ecRes = await fetchEContract(ecParams)
					const orderList = ecRes.resultObject.data[0].setup
					let prdNm = orderList[0] ? orderList[0].GOODS_NM : '제품명'
					let planDt = orderList[0].FIRST_DT ?? ''
					let count =
						orderList && orderList.length > 1 ? orderList.length - 1 : ''
					let ordNoStr = ''
					orderList.forEach((res, i) => {
						if (i === 0) {
							ordNoStr = `${res.ORDER_NO}`
						} else {
							ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
						}
					})
					let sumAmt = ecRes.resultObject.data[0].sumAmt
					sumAmt.MONTH_AMT_DC = this.$store.getters['pay/monthAmtDc']
					sumAmt.MONTH_AMT_TOTAL = sumAmt.MONTH_AMT - sumAmt.MONTH_AMT_DC

					let atParams = {
						reqCd: this.isPreContract ? '6' : '2', // 1 : 계약서발송, 2 : 주문완료, 3 : 서명완료재진입
						gubun: this.custSelf ? '1' : '2', // 1 : 고객직접, 2 : 코디대행
						name: this.$store.getters['verify/custName'],
						phone: this.$store.getters['verify/custPhone'],
						orderNo: ordNoStr,
						url: `${process.env.VUE_APP_FRT_URL}/order/confirm`, // 주문확정 링크
						orderInfoId: this.$store.getters['verify/mobOrderNo'], // 웹주문번호
						customerNo: this.$store.getters['verify/custNo'],
						codyNo: this.$store.getters['common/codyNo'],
						codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
						goodsNm: prdNm,
						planDt: planDt,
						goodsInfo: count !== '' ? `${prdNm} 외 ${count}대` : `${prdNm}`,
						onceAmt: this.$options.filters.comma(
							this.webOrderSum.totalSumOfRecpAmt
						),
						monthAmt: this.$options.filters.comma(
							this.webOrderSum.totalSumOfMonthAmt
						)
					}

					await sendAlimTalk(atParams)

					this.$router.push({ name: 'order-status' })
				} else {
					// 설치정보
					this.$router.push({ name: 'order-inst' })
				}
			} else {
				const options = {
					title: '다음과 같은 사유로 수납이 거부됐습니다.',
					guide: '수납방법을 재선택 바랍니다.',
					contents: {
						거부사유: rtn.MESSAGE
					},
					okText: '수납방법 재선택',
					cancelText: ''
				}

				await this.$refs.recpPop.open(options)
			}
		}
	}
}
</script>

<style scoped lang="scss"></style>
